import { debug } from "console";
import { stat } from "fs";
import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import { ColegiosDropDown } from "../colegios/ColegiosDropDown";
import { CustomDatePicker } from "../helpers/CustomDatePicker";
import { EstadoPedidoScholarumDropDown, YesNoDropDown } from "../helpers/EnumDropDown";
import { RouteHelper } from "../IntranetRouter";
import { LicenciaContenidosEditTabs } from "../licenciasColegio/Edit";
import { EstadoPedidoScholarum, PedidosTiendaSearch, PedidoTiendaListItem, PedidoTiendaListItemPaginatedList, SortDirection, TipoUsuario, YesNo } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { ExcelIcon } from "../utils/Icons";
import { toCamelCase } from "../utils/ValuesFormatter";
import { PedidosTiendaService } from "./PedidosTiendaService";


interface PedidosTiendaSearchEx extends PedidosTiendaSearch {
    colegio: string;
}

class IndexComp extends IndexPageBase<PedidosTiendaSearchEx, PedidoTiendaListItemPaginatedList, PedidoTiendaListItem>
{

    protected _getInitialSearchParams(): PedidosTiendaSearchEx {

        let model = super._getInitialSearchParams();

        model.fechaCompraFrom = ClientContext.getCursoEscolarActivo().fechaInicioCampagna;
        model.orderMode = SortDirection.Desc;
        model.colegioId = ClientContext.Current.colegioId;
        model.colegio = ClientContext.Current.nombreColegio;
        return model;
    }

    protected _getApiService(): IServiceForIndex<PedidosTiendaSearch, PedidoTiendaListItemPaginatedList> {
        return new PedidosTiendaService();
    }


    protected _enableExcelExport() {
        return true;
    }


    protected _renderSearchForm(): React.ReactNode {

        if (this.props.isEmbedded) {
            return null;
        }

        return <React.Fragment>


            <Col xs="12" md="6" lg="3">
                <FormGroup>

                    <ColegiosDropDown
                        label={I18n.Strings.colegio.entityName}

                        onChanged={(colegioId, colegioNombre) => {

                            var state = this._cloneStateForSetState();
                            state.searchParams.colegioId = colegioId;
                            state.searchParams.colegio = colegioNombre;
                            this.setState(state);
                        }}
                        id={nameof(this.state.searchParams.colegioId)}
                        selectedItemValue={this.state.searchParams.colegioId || 0}
                        selectedItemText={this.state.searchParams.colegio || ""}
                        emptyText=""
                    />
                </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.nombreCliente)}>
                        {I18n.Strings.pedidos.cliente}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nombreCliente = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.pedidos.cliente + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombreCliente)}
                        id={nameof(this.state.searchParams.nombreCliente)}
                        value={this.state.searchParams.nombreCliente || ""}
                    />
                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.nombreUsuario)}>
                        {I18n.Strings.getEnumText("TipoUsuario.Alumno")}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var newSearch = { ...this.state.searchParams, nombreUsuario: evt.target.value } as PedidosTiendaSearchEx;
                            this.setState({ searchParams: newSearch });
                        }}
                        placeholder={I18n.Strings.getEnumText("TipoUsuario.Alumno") + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombreUsuario)}
                        id={nameof(this.state.searchParams.nombreUsuario)}
                        value={this.state.searchParams.nombreUsuario || ""}
                    />
                </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.numPedido)}>
                        {I18n.Strings.pedidos.numPedido}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            if (evt.target.value == "") {
                                state.searchParams.numPedido = null;
                            }
                            else {
                                state.searchParams.numPedido = parseInt(evt.target.value);
                            }

                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.pedidos.numPedido + "..."}
                        type="number"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.numPedido)}
                        id={"usuarios" + nameof(this.state.searchParams.numPedido)}
                        value={this.state.searchParams.numPedido || ""}
                    />
                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.sku)}>
                        {I18n.Strings.pedidos.sku}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.sku = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.pedidos.sku + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.sku)}
                        id={"usuarios" + nameof(this.state.searchParams.sku)}
                        value={this.state.searchParams.sku || ""}
                    />
                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="2">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.estadoPedidoScholarum)}>
                        {I18n.Strings.pedidos.estado}
                    </Label>
                    <EstadoPedidoScholarumDropDown
                        emptyText={I18n.Strings.pedidos.estado + "..."}
                        label={null}
                        id={nameof(this.state.searchParams.estadoPedidoScholarum)}
                        value={this.state.searchParams.estadoPedidoScholarum}
                        required={true}
                        errorMessage={null}
                        onChanged={(value: string) => {
                            var newState = this._cloneStateForSetState();
                            if (value != '') {
                                newState.searchParams.estadoPedidoScholarum = value as EstadoPedidoScholarum;
                            }
                            else {
                                newState.searchParams.estadoPedidoScholarum = null;
                            }
                            this.setState(newState, () => this._performSearch());
                        }}
                    />
                </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="1">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.conDescuento)}>
                        {I18n.Strings.pedidos.descuento}
                    </Label>
                    <YesNoDropDown
                        emptyText={""}
                        label={I18n.Strings.pedidos.descuento}
                        id={nameof(this.state.searchParams.conDescuento)}
                        value={this.state.searchParams.conDescuento == null ? null : this.state.searchParams.conDescuento ? YesNo.Yes.toString() : YesNo.No.toString()}
                        className={"form-control"}
                        required={true}
                        onChanged={(value: YesNo) => {
                            var newState = this._cloneStateForSetState();
                            newState.searchParams.conDescuento = value == YesNo.Yes.toString() ? true : value == YesNo.No.toString() ? false : null;
                            this.setState(newState, () => this._performSearch());
                        }}
                    />
                </FormGroup>
            </Col>


            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.fechaCompraFrom)}>
                        {I18n.Strings.pedidos.fechaCompraFrom}
                    </Label>
                    <CustomDatePicker
                        key={nameof(this.state.searchParams.fechaCompraFrom)}
                        value={this.state.searchParams.fechaCompraFrom}
                        yearsFront={2}
                        yearsBack={3}
                        onChange={(val) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.fechaCompraFrom = val;
                            this.setState(state, () => this._performSearch());
                        }}
                    />
                </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.fechaCompraTo)}>
                        {I18n.Strings.pedidos.fechaCompraTo}
                    </Label>
                    <CustomDatePicker
                        key={nameof(this.state.searchParams.fechaCompraTo)}
                        value={this.state.searchParams.fechaCompraTo}
                        yearsFront={2}
                        yearsBack={3}
                        onChange={(val) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.fechaCompraTo = val;
                            this.setState(state, () => this._performSearch());
                        }}
                    />
                </FormGroup>
            </Col>

        </React.Fragment>;
    }



    protected _getTableColumns(): TableColumn<PedidoTiendaListItem>[] {

        let columns = [

            {
                fieldName: nameof(this.state.searchResults.items[0].externalId),
                title: I18n.Strings.pedidos.numPedido,
                renderField: (item) => item.externalId
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].nombreCliente),
                title: I18n.Strings.pedidos.cliente,
                renderField: (item) => item.nombreCliente
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].fechaPedido),
                title: I18n.Strings.pedidos.fechaCompra,
                renderField: (item) => I18n.Strings.formatDateTime(item.fechaPedido)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].estadoPedidoScholarum),
                title: I18n.Strings.pedidos.estado,
                renderField: (item) => I18n.Strings.getEnumText(`EstadoPedidoScholarum.${item.estadoPedidoScholarum}`)
            },

            {
                fieldName: nameof(this.state.searchResults.items[0].descuento),
                title: I18n.Strings.pedidos.descuento,
                renderField: (item) => I18n.Strings.formatNumber(item.descuento)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].licencias),
                title: I18n.Strings.pedidos.licencias,
                renderField: (item) => {
                    if ((item.licencias || "").length == 0) {
                        return "N/A";
                    } else {
                        let licencias = item.licencias.split(",");
                        return <React.Fragment>
                            {licencias.map((lic, index) => {
                                var partes = lic.split("#");

                                return <div>
                                    {(ClientContext.Current.tipoUsuario == TipoUsuario.VisorPedidos
                                        || ClientContext.Current.tipoUsuario == TipoUsuario.AdminInstitucion) && <>
                                            <span>{partes[1]}</span>
                                        </>}
                                    {(ClientContext.Current.tipoUsuario != TipoUsuario.VisorPedidos
                                        && ClientContext.Current.tipoUsuario != TipoUsuario.AdminInstitucion) && <>
                                            <Link className="mr-2" to={RouteHelper.editLicenciasColegio(partes[0], null, null, LicenciaContenidosEditTabs.Datos)}>
                                                {partes[1]}
                                            </Link>
                                        </>}
                                </div>;
                            })}
                        </React.Fragment>;
                    }
                    I18n.Strings.formatDateTime(item.fechaPedido)
                }
            }
        ] as TableColumn<PedidoTiendaListItem>[];


        if (
            ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal ||
            ClientContext.Current.tipoUsuario == TipoUsuario.AdminInstitucion ||
            ClientContext.Current.tipoUsuario == TipoUsuario.VisorPedidos
        ) {
            columns.unshift({
                fieldName: nameof(this.state.searchResults.items[0].colegio),
                title: toCamelCase(I18n.Strings.colegio.entityName),
                renderField: (item) => item.colegio
            });
        }

        return columns;
    }



    protected _getPageTitle(): string {
        return I18n.Strings.pedidos.title;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.pedidos.description;
    }

    protected _getNewItemUrl(): string {
        return null;
    }

    protected _getEditionItemUrl(item: PedidoTiendaListItem): string {
        return RouteHelper.detallePedidoTienda(item.id);
    }


    protected _toolbarRightContents(): JSX.Element[] {
        var elems = super._toolbarRightContents();

        elems.push(
            <Button
                key="excelExport"
                className="btn-rounded"
                color="secondary"
                onClick={() => this._exportExcelCompleto(this.state.searchParams)}
            >
                <ExcelIcon cssClass="pe-2" />
                Exportación completa
            </Button>
        );

        return elems;
    }
    private _exportExcelCompleto(sarchModel: PedidosTiendaSearchEx): void {
        this.setState({ showLoadingIcon: true },
            () => {
                try {
                    var service = new PedidosTiendaService();
                    service.exportExcelCompleto(sarchModel)
                        .then(() => this.setState({ showLoadingIcon: false }))
                        .catch((reason) => this._showError(reason));
                } catch (error) {
                    this._showError(error);
                }
            });
    }

}

export const Index = withRouter(IndexComp);